import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navigation from "./routes/Navigation";
import Home from "./component/Image";
import Video from "./component/Video";
import { useContext, useEffect } from "react";
import { ThemeContext } from "./context/context";
import Login from "./routes/Login";
import PrivateRoute from "./routes/PrivateRoute";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./utils/firebase.utils";

function App() {
  const { darkMode } = useContext(ThemeContext);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      // if user exists
      if (user) {

      } else {

      }
    });
    return listen;
  }, []);
  return (
    <div className={`App ${darkMode ? "dark" : null}`}>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Login />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/images" element={<Home />} />
            <Route path="/videos" element={<Video />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
