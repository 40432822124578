import React, { useState } from "react";

export const GalleryContext = React.createContext({
  galleryToggle: null,
  setGalleryToggle: () => null,
});

export const GalleryProvider = ({ children }) => {
  const [galleryToggle, setGalleryToggle] = useState(false);
  const state = { galleryToggle, setGalleryToggle };
  return (
    <GalleryContext.Provider value={state}> {children}</GalleryContext.Provider>
  );
};

// theme context
export const ThemeContext = React.createContext({
  darkMode: true,
  setDarkMode: () => null,
});

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const state = { darkMode, setDarkMode };
  return (
    <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
  );
};

