import React, { useContext } from "react";
import { GalleryContext } from "../context/context";

const Gallery = ({ pictureFile }) => {
  const { galleryToggle, setGalleryToggle } = useContext(GalleryContext);
  return (
    <div
      onClick={() => setGalleryToggle(!galleryToggle)}
      className="popup-image"
    >
      <img src={pictureFile} alt="" />
    </div>
  );
};

export default Gallery;
