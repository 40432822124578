// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBj9-Nwu_dRcDiJwoj5gw-_rL0NeKVUMjc",
  authDomain: "osktest-68e77.firebaseapp.com",
  projectId: "osktest-68e77",
  storageBucket: "osktest-68e77.appspot.com",
  messagingSenderId: "246535985123",
  appId: "1:246535985123:web:b8f4503330fadf1b6c72d6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth();

export const signInUser = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const signOutUser = () => signOut(auth);

// Fetch json data
export const fetchJSON = async () => {
  const jsonRef = ref(storage, "/json/imagedata.json");
  try {
    const url = await getDownloadURL(jsonRef);
    const resp = await fetch(url);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchVidJSON = async () => {
  const jsonRef = ref(storage, "/json/videodata.json");
  try {
    const url = await getDownloadURL(jsonRef);
    const resp = await fetch(url);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
