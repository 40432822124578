import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { fetchJSON, fetchVidJSON, signInUser } from "../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setCurrentUser, setImageData, setVideoData } from "../store/userSlice";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const nav = useNavigate();
  
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInUser(email, password);
      dispatch(setCurrentUser(userCredential.user.uid));

      const data = await fetchJSON();
      const videoData = await fetchVidJSON();
      dispatch(setVideoData(videoData));
      dispatch(setImageData(data));

       nav("/images");
      console.log(userCredential);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <section className="login-section">
      <form className="login-form" onSubmit={handleSignIn}>
        <label htmlFor="">email</label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <button>Login</button>
      </form>
    </section>
  );
};

export default Login;
