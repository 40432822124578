import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  currentUser: "",
  imageData: "",
  videoData: "",
  currentAudio: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },

    setImageData: (state, action) => {
      state.imageData = action.payload;
    },
    setVideoData: (state, action) => {
      state.videoData = action.payload;
    },

    setCurrentAudio: (state, action) => {
      state.currentAudio = action.payload;
    },
  },
});

export const { setCurrentUser, setImageData, setVideoData, setCurrentAudio } =
  userSlice.actions;
export const userReducer = userSlice.reducer;
export const selectUser = (state) => state.user.currentUser;
export const selectImageData = (state) => state.user.imageData;
export const selectVideoData = (state) => state.user.videoData;
export const selectCurrentAudio = (state) => state.user.currentAudio;

