import React, { useContext } from "react";
import "./Image.css";
// import imageData from "../assets/imagedata.json";
import { useState } from "react";
import ImageCards from "./ImageCards";
import Gallery from "./Gallery";
import { GalleryContext } from "../context/context";
import { useSelector } from "react-redux";
import { selectCurrentAudio, selectImageData } from "../store/userSlice";

const Home = () => {
  const imageData = useSelector(selectImageData);
  const { galleryToggle, setGalleryToggle } = useContext(GalleryContext);
  const [pictureFile, setPictureFile] = useState("");

  const audioFile = useSelector(selectCurrentAudio)
  const handleImgClick = (e) => {
    setPictureFile(e.target.src);
    setGalleryToggle(!galleryToggle);
  };

  return (
    <>
      <div className="innerWidth flexCenter card-container">
        {imageData
          ? imageData.map((card, i) => {
              return (
                <ImageCards
                  key={i}
                  card={card}
                  handleImgClick={handleImgClick}
                />
              );
            })
          : null}

        {
          <audio
            key={audioFile}
            title={`${audioFile}`}
            controls
            className="myAudio"
            loop
          >
            <source src={audioFile} type="audio/mpeg"></source>
          </audio>
        }
      </div>
      {galleryToggle && <Gallery pictureFile={pictureFile} />}
    </>
  );
};

export default Home;
