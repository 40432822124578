import React, { useContext, useState } from "react";
import { ThemeContext } from "../context/context";
import { storage } from "../utils/firebase.utils";
import { getDownloadURL, ref } from "firebase/storage";
import ReactPlayer from "react-player";

const VideoCards = ({ card }) => {
  const { name, description, video } = card;
  const [videoUrl, setVideoUrl] = useState("");
  const [buttonState, setButtonState] = useState(true);

  const fetchVideoUrl = async () => {
    try {
      // Specify the path to your video in the storage
      const videoRef = ref(storage, video);
      //Convert reference into an URL
      await getDownloadURL(videoRef).then((url) => {
        setVideoUrl(url);
      });
    } catch (error) {
      console.error("Error fetching video URL:", error);
    }

    setButtonState(!buttonState);
  };
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`flexCenter video-card ${darkMode ? "dark" : null}`}>
      <div className=" text-div">
        <h2 className="card-title">{name}</h2>
        <p className="card-description">{description}</p>
      </div>

      <button
        className={`${darkMode ? "dark" : null}`}
        onClick={() => fetchVideoUrl()}
      >
        {buttonState ? "Play" : "Close"}
      </button>
      {!buttonState && (
        <div className="video-wrapper">
          <ReactPlayer
            className="video"
            url={videoUrl}
            controls
            loop
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default VideoCards;

//   useEffect(() => {
//     const fetchVideoUrl = async () => {
//       try {
//         // Specify the path to your video in the storage
//         const videoRef = ref(storage, "姪っ子小便器に立ちしょん.mp4");
//         //convert reference into an URL
//         await getDownloadURL(videoRef).then((url) => {
//           setVideoUrl(url);
//         });
//       } catch (error) {
//         console.error("Error fetching video URL:", error);
//       }
//     };

//     fetchVideoUrl();
//   }, []);
