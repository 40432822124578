import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/context";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setCurrentAudio } from "../store/userSlice";

const ImageCards = ({ card, handleClick, handleImgClick }) => {
  const { darkMode } = useContext(ThemeContext);
  const { name, description, sound, picture } = card;
  const [imageUrl, setImageUrl] = useState("");
  const [soundUrl, setSoundUrl] = useState("");
  const dispatch = useDispatch()
  useEffect(() => {
    const imageRef = ref(storage, picture);
    // const soundRef = ref(storage, sound);

    const fetchImages = async () => {
      try {
        const imageUrlResp = await getDownloadURL(imageRef);
        setImageUrl(imageUrlResp);
      } catch (error) {}
    };

    // const fetchSounds = async () => {
    //   try {
    //     const soundUrlResp = await getDownloadURL(soundRef);
    //     setSoundUrl(soundUrlResp);
    //     console.log("success", picture);
    //   } catch (error) {
    //     console.log(error, sound);
    //   }
    // };

    fetchImages();
    // fetchSounds();
  }, []);

  const handleAudioPlay = async () => {
    // fetch the soundfile from firebase
    if (!sound) return;

    const soundRef = ref(storage, sound);
    try {
      const soundUrlResp = await getDownloadURL(soundRef);
      dispatch(setCurrentAudio(soundUrlResp))
      console.log("success", soundUrlResp);
    } catch (error) {
      console.log(error, sound);
    }
  };

  return (
    <div className={`flexCenterCol card ${darkMode ? "dark" : null}`}>
      <h1
        style={!sound ? { color: "red" } : { color: "black" }}
        onClick={() => handleAudioPlay()}
        className="card-title"
      >
        {name}
      </h1>
      <div className="img-container">
        <img onClick={handleImgClick} src={imageUrl} alt="card" />
      </div>
      <span className="card-description">{description}</span>
    </div>
  );
};

export default ImageCards;
