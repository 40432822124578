import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from '../store/userSlice';
import { useSelector } from 'react-redux';


const PrivateRoute = () => {
    const user = useSelector(selectUser)

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute