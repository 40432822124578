import React, { useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./Navigation.css";
import { ThemeContext } from "../context/context";
import { signOutUser } from "../utils/firebase.utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setCurrentUser } from "../store/userSlice";
const Navigation = () => {
  const user = useSelector(selectUser)
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch()
  const handleClick = () => {
    setDarkMode(!darkMode);
  };
  const nav = useNavigate()

  const handleSignOut = async () => {
    await signOutUser()
    dispatch(setCurrentUser(''))
    nav('/')
  }
  return (
    <>
      {user && (
        <div className="flexCenter paddings nav-container">
          {user && (
            <button
              onClick={() => handleSignOut()}
              className={`${darkMode ? "dark" : ""}`}
            >
              sign out
            </button>
          )}
          <Link to="/images" className={`nav-text ${darkMode ? "dark" : ""}`}>
            Image
          </Link>
          <Link to="/videos" className={`nav-text ${darkMode ? "dark" : ""}`}>
            Video
          </Link>

          
          <button onClick={handleClick} className={`${darkMode ? "dark" : ""}`}>
            Dark Mode
          </button>
          <button>{user}</button>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default Navigation;
