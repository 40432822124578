import React from "react";

import "./Video.css";
import VideoCards from "./VideoCards";
import { useSelector } from "react-redux";
import { selectVideoData } from "../store/userSlice";
const Video = () => {
  const vidData = useSelector(selectVideoData);
  return (
    <div className="flexCenterCol paddings video-container">
      {vidData
        ? vidData.map((card, i) => {
            return <VideoCards card={card} key={i} />;
          })
        : null}
    </div>
  );
};

export default Video;
